import CryptoJS from "crypto-js"
export const randomString = (length) => {
	let ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	ALPHABET += 'abcdefghijklmnopqrstuvwxyz';
	ALPHABET += '0123456789';
	let str = '';
	for (let i = 0; i < length; i++) {
		let rand = Math.floor(Math.random() * ALPHABET.length);
		str += ALPHABET.substring(rand, rand+1);
	}
	return str;
}

//防抖
export function debounce(callback) {
  let timer;
  return function(...args) {
    timer && window.clearTimeout(timer);
    timer = setTimeout(() => {
      callback && callback(...args);
    }, 900);
  }
}
export const decryptData = (str) => {
	let key, iv;
	key = iv = "yikejiaoyu_2016u"
	key = CryptoJS.enc.Utf8.parse(key)
	iv = CryptoJS.enc.Utf8.parse(iv)
	const decryptedData = CryptoJS.AES.decrypt(str, key, {
		iv,
		padding: CryptoJS.pad.Pkcs7
	})
	const jsonString = decryptedData.toString(CryptoJS.enc.Utf8)
	return JSON.parse(jsonString)
}

export const endDate = () => {
	return Date.parse("2024-04-14 00:00:00");
}

export const defaultDay = () => "2024-04-14";
